const initialState = {
  credit_check: false,
};

// eslint-disable-next-line default-param-last
const OCACreditCheck = (state = initialState, action) => {
  let newState;

  if (action.type === 'RESET_OCA_CREDIT_CHECK') {
    return { ...initialState };
  }

  if (action.type === 'SET_CREDIT_CHECK') {
    newState = { ...state };
    newState.creditCheck = action.payload;
    return newState;
  }

  return state;
};

export default OCACreditCheck;
