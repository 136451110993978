const initialState = {
  // Payment Details
  grossAnnualSalary: '',
  monthlyIncome: '',
  monthlyMortgageRent: '',
  monthlyCarPayments: '',
  monthlyOtherOutgoings: '',

  // Bank Account Details
  bankName: '',
  bankAddress: '',
  yearsWithBank: '',
  monthsWithBank: '',
  bankAccountName: '',
  bankAccountNumber: '',
  bankSortCode: '',
};

// eslint-disable-next-line default-param-last
const OCAFinances = (state = initialState, action) => {
  let newState;

  if (action.type === 'RESET_OCA_FINANCES_STATE') {
    return { ...initialState };
  }

  // Payment Details
  if (action.type === 'SET_GROSS_ANNUAL_SALARY') {
    newState = { ...state };
    newState.grossAnnualSalary = action.payload;
    return newState;
  }

  if (action.type === 'SET_MONTHLY_INCOME') {
    newState = { ...state };
    newState.monthlyIncome = action.payload;
    return newState;
  }

  if (action.type === 'SET_MONTHLY_MORTGAGE_RENT') {
    newState = { ...state };
    newState.monthlyMortgageRent = action.payload;
    return newState;
  }

  if (action.type === 'SET_MONTHLY_CAR_PAYMENTS') {
    newState = { ...state };
    newState.monthlyCarPayments = action.payload;
    return newState;
  }

  if (action.type === 'SET_MONTHLY_OTHER_OUTGOINGS') {
    newState = { ...state };
    newState.monthlyOtherOutgoings = action.payload;
    return newState;
  }

  // Bank Account Details
  if (action.type === 'SET_BANK_NAME') {
    newState = { ...state };
    newState.bankName = action.payload;
    return newState;
  }

  if (action.type === 'SET_BANK_ADDRESS') {
    newState = { ...state };
    newState.bankAddress = action.payload;
    return newState;
  }

  if (action.type === 'SET_YEARS_WITH_BANK') {
    newState = { ...state };
    newState.yearsWithBank = action.payload;
    return newState;
  }

  if (action.type === 'SET_MONTHS_WITH_BANK') {
    newState = { ...state };
    newState.monthsWithBank = action.payload;
    return newState;
  }

  if (action.type === 'SET_BANK_ACCOUNT_NAME') {
    newState = { ...state };
    newState.bankAccountName = action.payload;
    return newState;
  }

  if (action.type === 'SET_BANK_ACCOUNT_NUMBER') {
    newState = { ...state };
    newState.bankAccountNumber = action.payload;
    return newState;
  }

  if (action.type === 'SET_BANK_SORT_CODE') {
    newState = { ...state };
    newState.bankSortCode = action.payload;
    return newState;
  }

  return state;
};

export default OCAFinances;
