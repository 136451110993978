import { combineReducers } from 'redux';

// Import other reducers
import filterControls from './filter-controls';
import eligibilityChecker from './eligibility-checker';

// Online Credit Application
import OCAVerify from './oca-verify';
import OCABankInfo from './oca-bank-info';
import OCACompanyInfo from './oca-company-info';
import OCAEmploymentInfo from './oca-employment-info';
import OCAFinances from './oca-finances';
import OCAPersonalInfo from './oca-personal-info';
import OCACreditCheck from './oca-credit-check';
import OCAOptOut from './oca-opt-out';
import DealReducer from './deal';
import specialOffers from './special-offers';

// Combine all reducers
const rootReducer = combineReducers({
  OCAVerify,
  OCABankInfo,
  OCACompanyInfo,
  OCAEmploymentInfo,
  OCAFinances,
  OCAPersonalInfo,
  OCACreditCheck,
  OCAOptOut,
  Deal: DealReducer,
  specialOffers,
  filterControls,
  eligibilityChecker,
});

export default rootReducer;
