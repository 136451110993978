/* eslint-disable */
/**
 * n.b. this plugin has been modified by us to fix a Safari resize bug
 * https://stackoverflow.com/questions/29940691/window-resize-in-safari-why-it-works-also-if-scroll-window-but-doesnt-r
*/

/*!
 * jquery-drawer v3.2.2
 * Flexible drawer menu using jQuery, iScroll and CSS.
 * http://git.blivesta.com/drawer
 * License : MIT
 * Author : blivesta <design@blivesta.com> (http://blivesta.com/)
 */

!(function (e) {
  typeof define === 'function' && define.amd
    ? define(['jquery'], e)
    : typeof exports === 'object'
      ? (module.exports = e(require('jquery')))
      : e(jQuery);
}((e) => {
  const t = void 0 !== document.ontouchstart;
  var s = {
    init(t) {
      let windowWidth = $(window).width();

      return (
        (t = e.extend(
          {
            iscroll: { mouseWheel: !0, preventDefault: !1 },
            showOverlay: !0,
          },
          t,
        )),
        (s.settings = {
          state: !1,
          events: { opened: 'drawer.opened', closed: 'drawer.closed' },
          dropdownEvents: {
            opened: 'shown.bs.dropdown',
            closed: 'hidden.bs.dropdown',
          },
        }),
        (s.settings.class = e.extend(
          {
            nav: 'drawer-nav',
            toggle: 'drawer-toggle',
            overlay: 'drawer-overlay',
            open: 'drawer-open',
            close: 'drawer-close',
            dropdown: 'drawer-dropdown',
          },
          t.class,
        )),
        this.each(function () {
          const n = this;
          const r = e(this);
          r.data('drawer')
              || ((t = e.extend({}, t)),
              r.data('drawer', { options: t }),
              s.refresh.call(n),
              t.showOverlay && s.addOverlay.call(n),
              e(`.${s.settings.class.toggle}`).on('click.drawer', () => {
                return s.toggle.call(n), n.iScroll.refresh();
              }),
              e(window).on('resize.drawer', () => {
                if ($(window).width() !== windowWidth) {
                  windowWidth = $(window).width();

                  return s.close.call(n), n.iScroll.refresh();
                }
              }),
              e(`.${s.settings.class.dropdown}`).on(
                `${s.settings.dropdownEvents.opened
                } ${
                  s.settings.dropdownEvents.closed}`,
                () => {
                  return n.iScroll.refresh();
                },
              ));
        })
      );
    },
    refresh() {
      this.iScroll = new IScroll(
        `.${s.settings.class.nav}`,
        e(this).data('drawer').options.iscroll,
      );
    },
    addOverlay() {
      const t = e(this);
      const n = e('<div>').addClass(
        `${s.settings.class.overlay} ${s.settings.class.toggle}`,
      );
      return t.append(n);
    },
    toggle() {
      const e = this;
      return s.settings.state ? s.close.call(e) : s.open.call(e);
    },
    open() {
      const n = e(this);
      return (
        t,
        n
          .removeClass(s.settings.class.close)
          .addClass(s.settings.class.open)
          .drawerCallback(() => {
            (s.settings.state = !0), n.trigger(s.settings.events.opened);
          })
      );
    },
    close() {
      const n = e(this);
      return (
        t && n.off('touchmove.drawer'),
        n
          .removeClass(s.settings.class.open)
          .addClass(s.settings.class.close)
          .drawerCallback(() => {
            (s.settings.state = !1), n.trigger(s.settings.events.closed);
          })
      );
    },
    destroy() {
      return this.each(function () {
        const t = this;
        const n = e(this);
        e(`.${s.settings.class.toggle}`).off('click.drawer'),
        e(window).off('resize.drawer'),
        e(`.${s.settings.class.dropdown}`).off(
          `${s.settings.dropdownEvents.opened
          } ${
            s.settings.dropdownEvents.closed}`,
        ),
        t.iScroll.destroy(),
        n
          .removeData('drawer')
          .find(`.${s.settings.class.overlay}`)
          .remove();
      });
    },
  };
  (e.fn.drawerCallback = function (t) {
    const s = 'transitionend webkitTransitionEnd';
    return this.each(function () {
      const n = e(this);
      n.on(s, function () {
        return n.off(s), t.call(this);
      });
    });
  }),
  (e.fn.drawer = function (t) {
    return s[t]
      ? s[t].apply(this, Array.prototype.slice.call(arguments, 1))
      : typeof t !== 'object' && t
        ? void e.error(`Method ${t} does not exist on jQuery.drawer`)
        : s.init.apply(this, arguments);
  });
}));
