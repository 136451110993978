const initialState = {
  // Verify Details
  email: '',
};

// eslint-disable-next-line default-param-last
const OCAVerify = (state = initialState, action) => {
  let newState;

  if (action.type === 'RESET_OCA_VERIFY_STATE') {
    return { ...initialState };
  }

  if (action.type === 'SET_OCA_VERIFY_EMAIL') {
    newState = { ...state };
    newState.email = action.payload;
    return newState;
  }

  return state;
};

export default OCAVerify;
