// polyfills:
// Element.closest
if (!Element.prototype.matches) {
  Element.prototype.matches = (
    Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector
  );
}
if (!Element.prototype.closest) {
  Element.prototype.closest = function(selector) {
    let el = this;
    while (el !== null && el.nodeType === 1) {
      if (el.matches(selector)) {
        return el;
      }
      el = el.parentElement || el.parentNode;
    }
    return null;
  };
}

// javascript:
function ready(fn) {
  if (document.readyState !== 'loading') {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
}

function togglePricing() {
  document.body.classList.toggle('is-business');
  const isBusiness = document.body.classList.contains('is-business');
  if (isBusiness) {
    document.cookie = 'pricing_type=is-business;path=/';
  } else {
    document.cookie = 'pricing_type=is-personal;path=/';
  }
  Turbolinks.visit(window.location);
  // document.dispatchEvent(
  //  new CustomEvent('pricing#typeChange', { detail: { isPersonal: !isBusiness } })
  // );
}

function getCookie(key) {
  const b = document.cookie.match(`(^|[^;]+)\\s*${key}\\s*=\\s*([^;]+)`);
  return b ? b.pop() : '';
}

ready(() => {
  if (getCookie('pricing_type') === 'is-business') {
    document.body.classList.add('is-business');
  }

  document.addEventListener('click', function(e) {
    for (let { target } = e; target && target !== this; target = target.parentNode) {
      if (target.matches('.c-price-switch__label')) {
        e.preventDefault();
        togglePricing.call(target, e);
        break;
      }
    }
  }, false);

  document.addEventListener('turbolinks:click', (event) => {
    const anchorElement = event.target;
    const isSamePageAnchor = (
      anchorElement.hash
      && anchorElement.origin === window.location.origin
      && anchorElement.pathname === window.location.pathname
    );

    if (isSamePageAnchor) {
      event.target.dataset.turbolinks = 'false';
    }
  });
});

function lazyLoadImages() {
  const imgDefer = document.querySelectorAll('img[data-src]');

  for (let i = 0; i < imgDefer.length; i++) {
    imgDefer[i].setAttribute('src', imgDefer[i].getAttribute('data-src'));
    imgDefer[i].removeAttribute('data-src');
  }
}

document.addEventListener('turbolinks:load', lazyLoadImages);
