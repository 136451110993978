const initialState = {
  // Employer Details
  employmentStatus: '',
  employer: '',
  jobTitle: '',
  yearsAtEmployer: '',
  monthsAtEmployer: '',

  // Employer Address
  employerPostcode: '',
  employerAddressLine1: '',
  employerAddressLine2: '',
  employerAddressLine3: '',
  employerTown: '',
  employerCounty: '',
  employerTelephone: '',

  // Previous employer Details
  previousEmploymentStatus: '',
  previousEmployer: '',
  previousJobTitle: '',
  previousEmployerPostcode: '',
  previousEmployerAddressLine1: '',
  previousEmployerAddressLine2: '',
  previousEmployerAddressLine3: '',
  previousEmployerTown: '',
  previousEmployerCounty: '',
  previousEmployerTelephone: '',
  yearsAtPreviousEmployer: '',
  monthsAtPreviousEmployer: '',
};

// eslint-disable-next-line default-param-last
const OCAEmploymentInfo = (state = initialState, action) => {
  let newState;

  if (action.type === 'RESET_OCA_EMPLOYMENT_INFO_STATE') {
    return { ...initialState };
  }

  // Employer Details
  if (action.type === 'SET_EMPLOYMENT_STATUS') {
    newState = { ...state };
    newState.employmentStatus = action.payload;
    return newState;
  }

  if (action.type === 'SET_EMPLOYER') {
    newState = { ...state };
    newState.employer = action.payload;
    return newState;
  }

  if (action.type === 'SET_JOB_TITLE') {
    newState = { ...state };
    newState.jobTitle = action.payload;
    return newState;
  }

  if (action.type === 'SET_YEARS_AT_EMPLOYER') {
    newState = { ...state };
    newState.yearsAtEmployer = action.payload;
    return newState;
  }

  if (action.type === 'SET_MONTHS_AT_EMPLOYER') {
    newState = { ...state };
    newState.monthsAtEmployer = action.payload;
    return newState;
  }

  // Employer Address
  if (action.type === 'SET_EMPLOYER_POSTCODE') {
    newState = { ...state };
    newState.employerPostcode = action.payload;
    return newState;
  }

  if (action.type === 'SET_EMPLOYER_ADDRESS_LINE_1') {
    newState = { ...state };
    newState.employerAddressLine1 = action.payload;
    return newState;
  }

  if (action.type === 'SET_EMPLOYER_ADDRESS_LINE_2') {
    newState = { ...state };
    newState.employerAddressLine2 = action.payload;
    return newState;
  }

  if (action.type === 'SET_EMPLOYER_ADDRESS_LINE_3') {
    newState = { ...state };
    newState.employerAddressLine3 = action.payload;
    return newState;
  }

  if (action.type === 'SET_EMPLOYER_TOWN') {
    newState = { ...state };
    newState.employerTown = action.payload;
    return newState;
  }

  if (action.type === 'SET_EMPLOYER_COUNTY') {
    newState = { ...state };
    newState.employerCounty = action.payload;
    return newState;
  }

  if (action.type === 'SET_EMPLOYER_TELEPHONE') {
    newState = { ...state };
    newState.employerTelephone = action.payload;
    return newState;
  }

  // Previous employer Details
  if (action.type === 'SET_PREVIOUS_EMPLOYMENT_STATUS') {
    newState = { ...state };
    newState.previousEmploymentStatus = action.payload;
    return newState;
  }

  if (action.type === 'SET_PREVIOUS_EMPLOYER') {
    newState = { ...state };
    newState.previousEmployer = action.payload;
    return newState;
  }

  if (action.type === 'SET_PREVIOUS_JOB_TITLE') {
    newState = { ...state };
    newState.previousJobTitle = action.payload;
    return newState;
  }

  if (action.type === 'SET_PREVIOUS_EMPLOYER_POSTCODE') {
    newState = { ...state };
    newState.previousEmployerPostcode = action.payload;
    return newState;
  }

  if (action.type === 'SET_PREVIOUS_EMPLOYER_ADDRESS_LINE_1') {
    newState = { ...state };
    newState.previousEmployerAddressLine1 = action.payload;
    return newState;
  }

  if (action.type === 'SET_PREVIOUS_EMPLOYER_ADDRESS_LINE_2') {
    newState = { ...state };
    newState.previousEmployerAddressLine2 = action.payload;
    return newState;
  }

  if (action.type === 'SET_PREVIOUS_EMPLOYER_ADDRESS_LINE_3') {
    newState = { ...state };
    newState.previousEmployerAddressLine3 = action.payload;
    return newState;
  }

  if (action.type === 'SET_PREVIOUS_EMPLOYER_TOWN') {
    newState = { ...state };
    newState.previousEmployerTown = action.payload;
    return newState;
  }

  if (action.type === 'SET_PREVIOUS_EMPLOYER_COUNTY') {
    newState = { ...state };
    newState.previousEmployerCounty = action.payload;
    return newState;
  }

  if (action.type === 'SET_PREVIOUS_EMPLOYER_TELEPHONE') {
    newState = { ...state };
    newState.previousEmployerTelephone = action.payload;
    return newState;
  }

  if (action.type === 'SET_YEARS_AT_PREVIOUS_EMPLOYER') {
    newState = { ...state };
    newState.yearsAtPreviousEmployer = action.payload;
    return newState;
  }

  if (action.type === 'SET_MONTHS_AT_PREVIOUS_EMPLOYER') {
    newState = { ...state };
    newState.monthsAtPreviousEmployer = action.payload;
    return newState;
  }

  return state;
};

export default OCAEmploymentInfo;
