/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import { Turbo } from '@hotwired/turbo-rails';

import 'core-js';

import 'scroll-snap-slider/src/scroll-snap-slider.css';
import { ScrollSnapSlider } from 'scroll-snap-slider/src/ScrollSnapSlider';

import './controllers/frontend';
import './controllers/shared';

require('@rails/ujs').start();
require('turbolinks').start(); // TODO look to remove this once Turbo is fully integrated

require('@rails/activestorage').start();
// eslint-disable-next-line import/no-unresolved
require('channels');

document.addEventListener('turbolinks:load', () => {
  const sliderElement = document.querySelector('.scroll-snap-slider');

  if (sliderElement) {
    const slider = new ScrollSnapSlider(sliderElement);

    const prev = document.querySelector('.indicators .arrow.prev');
    const next = document.querySelector('.indicators .arrow.next');

    const updateArrows = function () {
      prev.classList.toggle('disabled', sliderElement.scrollLeft === 0);
      next.classList.toggle(
        'disabled',
        sliderElement.scrollLeft + sliderElement.offsetWidth === sliderElement.scrollWidth,
      );
    };

    prev.addEventListener('click', () => {
      slider.slideTo(slider.slide - 1);
    });

    next.addEventListener('click', () => {
      slider.slideTo(slider.slide + 1);
    });

    slider.addEventListener('slide-pass', updateArrows);
    slider.addEventListener('slide-stop', updateArrows);

    slider.slideTo(0);
  }
});

window.IScroll = require('iscroll');
// Import jQuery and expose jQuery keywords so they can be declared
require('jquery');

window.jQuery = $;
window.$ = $;

// Import Bootstrap, load styles and optionally load Tooltips and Popovers everywhere
require('bootstrap');

document.addEventListener('turbolinks:load', () => {
  $(() => {
    $('[data-toggle="tooltip"]').tooltip({ boundary: 'window' });
    $('[data-toggle="popover"]').popover();
  });
});

require('waypoints/lib/jquery.waypoints.min.js');
require('waypoints/lib/shortcuts/sticky.min.js');

// Cookies
window.Cookies = require('js-cookie/src/js.cookie.js');

// JS packs
require('packs/patterns/main.js');
require('packs/patterns/frontend.js');
require('packs/patterns/drawer.js');
require('packs/patterns/mobile-drawer.js');
require('packs/patterns/sticky.js');

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// Support component names relative to this directory:
const componentRequireContext = require.context('components', true);
const ReactRailsUJS = require('react_ujs');

ReactRailsUJS.useContext(componentRequireContext);
ReactRailsUJS.detectEvents();

Turbo.session.drive = false;
