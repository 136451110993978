// Action Types
const SET_ELIGIBILITY_ANSWER = 'SET_ELIGIBILITY_ANSWER';
const RESET_ELIGIBILITY = 'RESET_ELIGIBILITY';
const GO_TO_NEXT_ELIGIBILITY_STEP = 'GO_TO_NEXT_ELIGIBILITY_STEP';
const GO_TO_PREVIOUS_ELIGIBILITY_STEP = 'GO_TO_PREVIOUS_ELIGIBILITY_STEP';
const GO_TO_ELIGIBILITY_STEP = 'GO_TO_ELIGIBILITY_STEP';

// Initial State
const initialState = {
  currentStep: 0,
  about_you: '',
  address_history: '',
  employment_status: '',
  employment_history: '',
  affordability: '',
  credit_history: '',
};

// Reducer
// eslint-disable-next-line default-param-last
const eligibilityReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ELIGIBILITY_ANSWER:
      return {
        ...state,
        [action.payload.step]: action.payload.value,
      };
    case RESET_ELIGIBILITY:
      return initialState;
    case GO_TO_NEXT_ELIGIBILITY_STEP:
      return {
        ...state,
        currentStep: Math.min(state.currentStep + 1, 5),
      };
    case GO_TO_PREVIOUS_ELIGIBILITY_STEP:
      return {
        ...state,
        currentStep: Math.max(state.currentStep - 1, 0),
      };
    case GO_TO_ELIGIBILITY_STEP:
      return {
        ...state,
        currentStep: action.payload.stepNumber,
      };
    default:
      return state;
  }
};

// Action Creators
export const setEligibilityAnswer = (step, value) => ({
  type: SET_ELIGIBILITY_ANSWER,
  payload: { step, value },
});

export const resetEligibility = () => ({
  type: RESET_ELIGIBILITY,
});

export const goToNextStep = () => ({
  type: GO_TO_NEXT_ELIGIBILITY_STEP,
});

export const goToPreviousStep = () => ({
  type: GO_TO_PREVIOUS_ELIGIBILITY_STEP,
});

export const goToSpecificStep = (stepNumber) => ({
  type: GO_TO_ELIGIBILITY_STEP,
  payload: { stepNumber },
});

export default eligibilityReducer;
