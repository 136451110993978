export const convertSpaces = (string, to = '%20') => {
  return to === '%20' ? string.replace(/\+/g, to) : string.replace(/%20/g, to);
};

export const isFilterArray = (string = '') => !!string.match(/\[\]$/);

export const getQueryStringParameters = () => {
  if (window.location.search === '') return {};

  const params = window.location.search.substring(1).split('&');
  return params.reduce((object, pair) => {
    // decodeURIComponent cannot be used directly to parse query parameters from a URL. [You need to replace the '+' with ' ']
    // Source: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/decodeURIComponent#decoding_query_parameters_from_a_url
    const [key, value] = decodeURIComponent(convertSpaces(pair)).split('=');

    // Handle array formatting in URL params e.g. make[]
    if (isFilterArray(key)) {
      if (key in object) {
        const newObject = { ...object };
        newObject[key].push(value);
        return newObject;
      }

      return {
        ...object,
        [key]: [value],
      };
    }

    return {
      ...object,
      [key]: value,
    };
  }, {});
};

// does the opposite of getQueryStringParameters by turning an object into an encoded querystring
export const objectToQueryStringParameters = (object) => {
  const params = [];

  const addParam = (key, value) => {
    params.push(`${encodeURIComponent(key)}=${convertSpaces(encodeURIComponent(value), '+')}`);
  };

  Object.keys(object).forEach((key) => {
    if (isFilterArray(key)) {
      object[key].forEach((value) => {
        addParam(key, value);
      });
    } else {
      addParam(key, object[key]);
    }
  });

  return params.join('&');
};

export const formToQueryString = (form, additionalParams = {}) => {
  let formData = {};

  form.querySelectorAll('[name]').forEach((field) => {
    if (field.nodeName !== 'BUTTON') {
      formData[field.name] = field.value;
    }
  });

  // let the additional params replace the form ones if needed
  formData = { ...formData, ...additionalParams };

  return Object.keys(formData)
    .map((fieldName) => `${fieldName}=${formData[fieldName]}`)
    .join('&');
};

//           curry :: (* → a) → (* → a)
export const curry = (fn) => {
  const arity = fn.length;

  return function $curry(...args) {
    if (args.length < arity) {
      return $curry.bind(null, ...args);
    }

    return fn.call(null, ...args);
  };
};

//    identity :: x -> x
const identity = (x) => x;

//           contains :: (x, [x]) -> Boolean
export const contains = (item, list) => list && list.indexOf(item) > -1;

//           unique :: [x] -> [x]
export const unique = (list) => [...new Set(list)];

//           without :: (x|[x], [x]) -> [x]
export const without = (item, list) => list.filter((x) => !contains(x, [item].flat()));

//           all :: [x] -> Boolean
export const all = (list) => list.every(identity);

//           any :: [x] -> Boolean
export const any = (list) => list.some(identity);

//    safely return the property at a nested object path
//    e.g. path(["a", "b", "c"], { a: { b: { c: "foo" } } }) => "foo"
//           path :: ([String], object]) -> Any?
export const path = (props, object) => {
  let currentProp = object;
  let index = 0;

  for (; index < props.length; ) {
    if (currentProp == null) {
      return;
    }

    currentProp = currentProp[props[index]];
    index += 1;
  }

  return currentProp;
};

//    returns the list of elements found in both lists
//           intersection :: -> ([x], [x]) -> [x]
export const intersection = (list1, list2) => {
  const lookupList = list1.length > list2.length ? list1 : list2;
  const filteredList = list1.length > list2.length ? list2 : list1;

  return unique(filteredList.filter((x) => contains(x, lookupList)));
};

// the below two helpers are duplicates of methods from the Deal React files
// we should remove that redundancy when we have some time
export const numberWithCommas = (n) => {
  if (typeof n !== 'number' && typeof n !== 'string') return '';
  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatPrice = (value) => {
  return `£${numberWithCommas(parseFloat(value).toFixed(2))}`;
};

export const currentChannelPath = (channelPath) => {
  const channelMatcher = /^\/[a-z]+-leasing\//;
  const matchedCurrentChannel = window.location.pathname.match(channelMatcher);
  const matchedPathChannel = channelPath.match(channelMatcher);
  const defaultChannel = '/car-leasing/';

  if (matchedPathChannel) {
    return channelPath.replace(
      matchedPathChannel[0],
      matchedCurrentChannel ? matchedCurrentChannel[0] : defaultChannel,
    );
  }

  return channelPath;
};
