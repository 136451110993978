import PropTypes from 'prop-types';

export const elasticSearchFilterOptionPropTypes = PropTypes.shape({
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.any]),
  label: PropTypes.string,
  prelabel: PropTypes.string,
  enabled: PropTypes.bool,
});

export const elasticSearchPropsPropTypes = PropTypes.shape({
  removeSelectedFilters: PropTypes.func,
  selectedFilters: PropTypes.shape({}),
  setSelectedBodytype: PropTypes.func,
  setSelectedFilter: PropTypes.func,
  setSelectedFilters: PropTypes.func,
  setSingleCheckboxFilter: PropTypes.func,
});

export const elasticSearchPropTypes = PropTypes.shape({
  allFiltersFor: PropTypes.func,
  availableFiltersFor: PropTypes.func,
  props: elasticSearchPropsPropTypes,
});
