const initialState = {
  // Business Bank Details
  businessBankName: '',
  yearsWithBusinessBank: '',
  monthsWithBusinessBank: '',
  businessAccountHolder: '',
  businessAccountNumber: '',
  businessSortCode: '',
};

// eslint-disable-next-line default-param-last
const OCABankInfo = (state = initialState, action) => {
  let newState;

  if (action.type === 'RESET_OCA_BANK_INFO_STATE') {
    return { ...initialState };
  }

  // Business Bank Details
  if (action.type === 'SET_BUSINESS_BANK_NAME') {
    newState = { ...state };
    newState.businessBankName = action.payload;
    return newState;
  }

  if (action.type === 'SET_YEARS_WITH_BUSINESS_BANK') {
    newState = { ...state };
    newState.yearsWithBusinessBank = action.payload;
    return newState;
  }

  if (action.type === 'SET_MONTHS_WITH_BUSINESS_BANK') {
    newState = { ...state };
    newState.monthsWithBusinessBank = action.payload;
    return newState;
  }

  if (action.type === 'SET_BUSINESS_ACCOUNT_HOLDER') {
    newState = { ...state };
    newState.businessAccountHolder = action.payload;
    return newState;
  }

  if (action.type === 'SET_BUSINESS_ACCOUNT_NUMBER') {
    newState = { ...state };
    newState.businessAccountNumber = action.payload;
    return newState;
  }

  if (action.type === 'SET_BUSINESS_SORT_CODE') {
    newState = { ...state };
    newState.businessSortCode = action.payload;
    return newState;
  }

  return state;
};

export default OCABankInfo;
