// Selector to check if all questions have been answered
export const areAllQuestionsAnswered = (state) => {
  const questions = [
    'about_you',
    'address_history',
    'employment_status',
    'employment_history',
    'affordability',
    'credit_history',
  ];
  return questions.every((question) => state[question] !== '');
};

// Selector to calculate the current score based on the answers
export const calculateCurrentScore = (state, steps) => {
  let maxScore = 1;
  steps.forEach((step) => {
    const answerValue = state[step.name];
    if (answerValue) {
      const selectedOption = step.options.find((option) => option.value === answerValue);
      if (selectedOption && selectedOption.score > maxScore) {
        maxScore = selectedOption.score;
      }
    }
  });
  return maxScore;
};
