const initialState = {
  priceFrom: '',
  priceTo: '',
  dataInitialised: false,
  activeDataInitialised: false,
};

// eslint-disable-next-line default-param-last
const specialOffers = (state = initialState, action) => {
  let newState;

  if (action.type === 'RESET_SPECIAL_OFFERS_STATE') {
    return { ...initialState };
  }

  if (action.type === 'RESET_SPECIAL_OFFERS_BUDGET') {
    newState = { ...state };
    newState.priceFrom = '';
    newState.priceTo = '';
    return newState;
  }

  if (action.type === 'SET_PRICE_RANGE') {
    newState = { ...state };
    newState.priceFrom = action.payload[0];
    newState.priceTo = action.payload[1];
    return newState;
  }

  if (action.type === 'SET_DRAWER_INITIAL') {
    newState = { ...state };
    newState[action.payload] = false;
    return newState;
  }

  if (action.type === 'SET_DRAWER_STATE') {
    newState = { ...state };
    newState[action.payload] = !newState[action.payload];
    return newState;
  }

  if (action.type === 'SPECIAL_OFFERS_INITIALISE_DATA') {
    newState = { ...state };
    newState.dataInitialised = true;
    return newState;
  }

  if (action.type === 'SPECIAL_OFFERS_INITIALISE_ACTIVE_DATA') {
    newState = { ...state };
    newState.activeDataInitialised = true;
    return newState;
  }

  return state;
};

export default specialOffers;
