(function() {
  const settings = {
    class: {
      subdrawer: 'g-drawer--sub',
      closedrawer: 'g-drawer__close',
      action: 'g-drawer__action',
      overlay: 'drawer-overlay',
      opened: 'drawer-open',
      hasdrawer: 'has-drawer',
    },
  };

  const elements = {
    $index: null,
    $overlay: null,
  };

  document.addEventListener('turbolinks:load', () => {
    // create the overlay
    const $overlay = document.createElement('div');
    $overlay.className = settings.class.overlay;
    document.body.appendChild($overlay);

    // cache some elements
    elements.$overlay = document.querySelector(`.${settings.class.overlay}`);
    elements.$index = document.querySelector('.l-index');
  });

  const openDrawer = function($drawer) {
    $drawer.classList.add(settings.class.opened);
    elements.$index.classList.add(settings.class.hasdrawer);
    elements.$overlay.style.display = 'block';
  };

  const closeDrawer = function($drawer) {
    $drawer.classList.remove(settings.class.opened);

    if (!$drawer.classList.contains(settings.class.subdrawer)) {
      // only remove the overlay when the main drawer is closed
      elements.$index.classList.remove(settings.class.hasdrawer);
      elements.$overlay.style.display = 'none';
    }
  };

  const closeAllDrawers = function() {
    const $drawers = document.querySelectorAll(`.${settings.class.opened}`);

    for (let i = 0; i < $drawers.length; i++) {
      closeDrawer($drawers[i]);
    }

    // broadcast a message that is read by FilterDrawerItem so they can mop up after this action
    window.dispatchEvent(new CustomEvent('scl:filters:close'));
  };

  // methods
  document.addEventListener('click', (event) => {
    const $element = event.target;

    // handle opening drawers
    if ($element.hasAttribute('data-drawer')) {
      event.preventDefault();
      openDrawer(document.querySelector($element.getAttribute('data-drawer')));
    }

    // element has parent with data-drawer
    if ($element.closest('[data-drawer]')) {
      event.preventDefault();
      openDrawer(document.querySelector($element.closest('[data-drawer]').getAttribute('data-drawer')));
    }

    // handle closing this drawer with the X or the <
    if ($element.classList.contains(settings.class.closedrawer)) {
      const $drawer = $element.closest(`.${settings.class.opened}`);
      event.preventDefault();
      closeDrawer($drawer);
    }

    // handle closing all drawers by clicking on the overlay
    // or clicking the button at the bottom of the drawer
    if ($element.classList.contains(settings.class.overlay)
      || $element.classList.contains(settings.class.action)
      || $element.getAttribute('data-drawer-action') === 'close'
    ) {
      closeAllDrawers();
    }
  });
}());
