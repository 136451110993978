const initialState = {
  opt_out: false,
};

// eslint-disable-next-line default-param-last
const OCAOptOut = (state = initialState, action) => {
  let newState;

  if (action.type === 'RESET_OCA_OPT_OUT') {
    return { ...initialState };
  }

  if (action.type === 'SET_OPT_OUT') {
    newState = { ...state };
    newState.optOut = action.payload;
    return newState;
  }

  return state;
};

export default OCAOptOut;
