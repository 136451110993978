// eslint-disable-next-line func-names
(function() {
  document.addEventListener('turbolinks:load', () => {
    const observer = new IntersectionObserver(
      ([event]) => {
        const element = event.target;
        // the element may be configured to stick to the bottom or top of the page
        const stickyBottom = element.getAttribute('data-sticky') === 'bottom';
        // we don't want to say it's fixed if you have scrolled past where it stops
        const pastEdge = stickyBottom
          ? event.boundingClientRect.y > 0
          : event.boundingClientRect.y < 0;
        const toggleOn = (event.intersectionRatio < 1 && pastEdge);

        // add/remove the class as needed
        element.classList.toggle('is-fixed', toggleOn);

        // the visible class is quite niche/specific and might not be used by every instance
        // it only is added once; the first time
        setTimeout(() => {
          element.classList.add('is-visible');
        }, 250);
      },
      { threshold: [1] },
    );

    const stickyElements = document.querySelectorAll('[data-sticky]');

    stickyElements.forEach((element) => observer.observe(element));
  });
}());
