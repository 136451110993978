import Cookies from 'js-cookie';

const ORIGINAL = 'Original';

export class SplitTestFeature {
  constructor(featureName, variants) {
    this.featureName = featureName;
    this.variants = [ORIGINAL, ...variants];
    this.variant = this._getVariant();
  }

  start() {
    const data = {
      event: 'ab_test',
      test_name: this.featureName,
      test_variant: this.variant,
    };

    dataLayer.push(data);
  }

  _getVariant() {
    const cookieName = `split-test-${this.featureName}`;
    const previousFeatureVariant = Cookies.get(cookieName);

    if (previousFeatureVariant) {
      return previousFeatureVariant;
    }

    // pick variant from variants
    const variant = this.variants[Math.floor(Math.random() * this.variants.length)];

    // set cookie
    Cookies.set(cookieName, variant, {
      expires: 365,
      sameSite: 'Lax',
      path: '/',
    });

    return variant;
  }
}

export function shouldShowFeature(splitTestName) {
  const hiddenVariant = 'Hidden';
  const currentVariant = window[`split-test-${splitTestName}`];

  return currentVariant !== hiddenVariant;
}
