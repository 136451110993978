/* eslint-disable */

// xs: 0,
// sm: 576px,
// md: 768px,
// lg: 992px,
// xl: 1200px,
// xxl: 1500px

(function () {
  // Tell the browser not to handle scrolling when restoring via the history or
  // when reloading
  if ('scrollRestoration' in history) {
    history.scrollRestoration = 'manual';
  }

  var SCROLL_POSITION = 'scroll-position';
  var PAGE_INVALIDATED = 'page-invalidated';
  var IS_TURBO_FRAME_UPDATE = false;

  // Persist the scroll position on refresh
  document.addEventListener('beforeunload', function () {
    sessionStorage.setItem(SCROLL_POSITION, JSON.stringify(scrollData()));
  });

  // Invalidate the page when the next page is different from the current page
  // Persist scroll information across pages
  function beforeVisitHandler(event) {
    var url;
    if (event.type === 'turbolinks:before-visit') {
      url = event.data.url;
    } else if (event.type === 'turbo:before-visit') {
      url = event.detail.url;
    }

    if (url !== location.href) {
      sessionStorage.setItem(PAGE_INVALIDATED, 'true');
    }
    sessionStorage.setItem(SCROLL_POSITION, JSON.stringify(scrollData()));
  }

  document.addEventListener('turbolinks:before-visit', beforeVisitHandler);
  document.addEventListener('turbo:before-visit', beforeVisitHandler);

  // Track turbo frame updates
  document.addEventListener('turbo:before-fetch-request', function (event) {
    if (event.detail.fetchOptions.headers['Turbo-Frame']) {
      IS_TURBO_FRAME_UPDATE = true;
    }
  });

  // Prevent scrolling after turbo frame replacement
  document.addEventListener('turbo:frame-render', function (event) {
    if (event.target.tagName === 'TURBO-FRAME') {
      event.preventDefault();
      IS_TURBO_FRAME_UPDATE = true;
    }
  });

  // When a page is fully loaded:
  // 1. Get the persisted scroll position
  // 2. If the locations match and the load did not originate from a page
  // invalidation,
  // 3. scroll to the persisted position if there, or to the top otherwise
  // 4. Remove the persisted information
  function loadHandler(event) {
    var scrollPosition = JSON.parse(sessionStorage.getItem(SCROLL_POSITION));

    if (scrollPosition && shouldScroll(scrollPosition)) {
      var savedElement = document.getElementById(scrollPosition.key);

      if (
        !IS_TURBO_FRAME_UPDATE &&
        savedElement &&
        window.history.state &&
        window.history.state.turbo
      ) {
        savedElement.scrollIntoView();
      } else if (!IS_TURBO_FRAME_UPDATE) {
        window.scrollTo(scrollPosition.scrollX, scrollPosition.scrollY);
      }
    }

    sessionStorage.removeItem(PAGE_INVALIDATED);
  }

  document.addEventListener('turbolinks:load', loadHandler);
  document.addEventListener('turbo:load', loadHandler);
  document.addEventListener('turbo:render', loadHandler);

  function shouldScroll(scrollPosition) {
    return (
      scrollPosition &&
      scrollPosition.location === location.href &&
      !JSON.parse(sessionStorage.getItem(PAGE_INVALIDATED))
    );
  }

  function scrollData() {
    var activeElement = document.querySelector('[data-key]:not([hidden])');
    return {
      scrollX: window.scrollX,
      scrollY: window.scrollY,
      location: location.href,
      key: activeElement ? activeElement.getAttribute('id') : null,
    };
  }
})();

function onPageLoad() {
  if (window.history.state && window.history.state.turbo) {
    window.addEventListener('popstate', function () {
      location.reload(true);
    });
  }
  /* Only allow transition on anchors when the page has loaded */
  $('body').removeClass('is-not-loaded');
  /* On thank you pages, open the enquiry modal by default */
  $('.p-thank-you-enquiry #modal-enquiry').modal('show');
  /* Call the page initialise function to lazy load assets */
}

document.addEventListener('turbolinks:load', onPageLoad);
document.addEventListener('turbo:load', onPageLoad);
document.addEventListener('turbo:render', onPageLoad);

function onSectionsLoad() {
  /* SECTION 1: Waypoints & sticky navigation */
  /* SECTION 2: Deal Page Photo Scroll Waypoint */
  /* SECTION 3: Modals */
  /* SECTION 4: Internal sales team dropdown */
  /* SECTION 5: Counter up */
  /* SECTION 6: Hero Content Toggle  - Moved to stimulus controller */
  /* SECTION 7: FAQs Mobile Scrolling */
  /* SECTION 8: Share This */
  /* SECTION 9: Anchor Scroll to Section */
  /* SECTION 10: Add tooltip custom class */

  /* SECTION 1: Waypoints & Sticky Navigation */
  (function () {
    var $search = $('.g-search'),
      $toolbar = $('.g-toolbar'),
      $header = $('.g-header');

    if ($header.length) {
      // After the header has finished it's CSS transition, update Waypoints to ensure offsets are correct
      $header.on(
        'transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd',
        function (e) {
          Waypoint.refreshAll();
        }
      );

      var stickyHeader = new Waypoint.Sticky({
        element: $header[0],
        stuckClass: 'is-fixed',
        wrapper: "<div class='sticky-wrapper sticky-wrapper--header' />",
      });

      $toolbar.waypoint(
        function (direction) {
          if (direction === 'down' && $(window).scrollTop() > 0) {
            if ($(window).width() > 992) {
              $('.sticky-wrapper--header').attr('style', 'height:51px');
            } else if ($(window).width() > 768) {
              $('.sticky-wrapper--header').attr('style', 'height:54px');
            } else {
              $('.sticky-wrapper--header').attr('style', 'height:0px');
            }
            $('.g-search').addClass('is-fixed');
            $('.g-main').addClass('has-fixed-search');
          } else {
            if ($(window).width() > 992) {
              $('.sticky-wrapper--header').attr('style', 'height:94px');
            } else if ($(window).width() > 768) {
              $('.sticky-wrapper--header').attr('style', 'height:85px');
            } else {
              $('.sticky-wrapper--header').attr('style', 'height:62px');
            }
            $('.g-search').removeClass('is-fixed');
            $('.g-main').removeClass('has-fixed-search');
          }
        },
        {
          offset: function () {
            return -$toolbar.height();
          },
        }
      );
    }

    var $filter_triggers = $('.c-filter-triggers');
    if ($filter_triggers.length) {
      var stickyTriggers = new Waypoint.Sticky({
        element: $filter_triggers[0],
        stuckClass: 'is-fixed',
        wrapper: "<div class='sticky-wrapper sticky-wrapper--filters' />",
        offset: function () {
          return $header.outerHeight();
        },
      });
    }

    var $anchored_nav = $('.c-anchored-nav');
    if ($anchored_nav.length) {
      var anchored_nav = new Waypoint.Sticky({
        element: $anchored_nav[0],
        stuckClass: 'is-fixed',
        wrapper: "<div class='sticky-wrapper sticky-wrapper--anchored-nav' />",
        offset: function () {
          return $header.outerHeight();
        },
      });

      var scrolling = false;

      $('.c-anchored-nav__link').on('click', function (e) {
        if (this.hash !== '' && scrolling === false) {
          e.preventDefault();
          var hash = this.hash;
          scrolling = true;
          $('html, body').animate(
            {
              scrollTop: $(hash).offset().top,
            },
            800,
            function () {
              window.location.hash = hash;
              scrolling = false;
            }
          );
          select_anchored_nav_link($(this));
        }
      });

      $('.c-anchored-nav__target').each(function (i, element) {
        $(element).waypoint({
          handler: function (direction) {
            if (scrolling === false && direction === 'down') {
              select_anchored_nav_link(
                $(
                  ".c-anchored-nav__link[href='" +
                    $(element).data('trigger') +
                    "']"
                )
              );
            }
          },
          offset: function () {
            return $header.outerHeight() + $anchored_nav.outerHeight();
          },
        });
        $(element).waypoint({
          handler: function (direction) {
            if (scrolling === false && direction === 'up') {
              select_anchored_nav_link(
                $(
                  ".c-anchored-nav__link[href='" +
                    $(element).data('trigger') +
                    "']"
                )
              );
            }
          },
          offset: 'bottom-in-view',
        });
      });
    }

    function select_anchored_nav_link($element) {
      $('.c-anchored-nav__link').removeClass('c-anchored-nav__link--selected');
      $element.addClass('c-anchored-nav__link--selected');
    }
  })();

  /* SECTION 2: Deal Page Photo Scroll Waypoint */
  (function () {
    var $dealPhotos = $('.g-deal-photos'),
      $dealPhotosThumbs = $('.g-deal-photos__thumbs-inner'),
      $dealPhotosThumbScroll = $('.g-deal-photos__thumbs-scroll');

    $dealPhotosThumbScroll.waypoint(
      function (direction) {
        if (direction === 'down' && $dealPhotosThumbs.scrollTop() > 0) {
          $dealPhotos.addClass('is-scrolled');
        } else {
          $dealPhotos.removeClass('is-scrolled');
        }
      },
      {
        context: $dealPhotosThumbs,
        offset: 'bottom-in-view',
      }
    );
  })();

  /* SECTION 3: Modals */
  (function () {
    $(window)
      .on('show.bs.modal', function (e) {
        $('body').addClass('has-modal');
      })
      .on('hide.bs.modal', function (e) {
        $('body').removeClass('has-modal');
      });

    $('.c-modal').on('hidden.bs.modal', function (e) {
      Waypoint.refreshAll();
    });

    $('.c-modal--video').on('hidden.bs.modal', function () {
      if (
        !$('.c-modal--video').hasClass('show') &&
        $('.c-modal').find('.c-video__asset').length > 0
      ) {
        $('.c-modal').find('.c-video__asset')[0].pause();
      }
    });
  })();

  /* SECTION 4: Internal sales team dropdown */
  (function () {
    $('.c-internal .dropdown').on({
      'shown.bs.dropdown': function () {
        this.was_click_on_menu = false;
      },
      click: function (event) {
        if (
          !$(event.target).is('a') &&
          ($(event.target).hasClass('dropdown-menu') ||
            $(event.target).parents('.dropdown-menu').length)
        ) {
          this.was_click_on_menu = true;
        }
      },
      'hide.bs.dropdown': function () {
        this.should_close = !this.was_click_on_menu;
        this.was_click_on_menu = false;
        return this.should_close;
      },
    });
  })();

  /* SECTION 5: Counter up */
  (function () {
    if ($('.totals-counter').length) {
      let a = 0;
      $(window).bind('scroll.total-counter', function () {
        const numTop = $('.totals-counter').offset().top - window.innerHeight;
        if (a == 0 && $(window).scrollTop() > numTop) {
          $('.totals-counter').each(function () {
            const $this = $(this),
              countTo = $this.attr('data-count');

            $({
              countNum: $this.text(),
            }).animate(
              {
                countNum: countTo,
              },

              {
                duration: 2000,
                easing: 'linear',
                step: function () {
                  $this.text(commaSeparateNumber(Math.floor(this.countNum)));
                },
                complete: function () {
                  $this.text(commaSeparateNumber(this.countNum));
                },
              }
            );
          });

          function commaSeparateNumber(val) {
            while (/(\d+)(\d{3})/.test(val.toString())) {
              val = val.toString().replace(/(\d+)(\d{3})/, '$1' + ',' + '$2');
            }
            return val;
          }
          a = 1;
        }
      });
    } else {
      $(window).unbind('scroll.total-counter');
    }
  })();

  /* SECTION 7: FAQs Mobile Scrolling */
  (function () {
    if ($(window).width() < 768) {
      $("[data-toggle='tab']").on('click', function (event) {
        setTimeout(function () {
          const name = event.target.getAttribute('aria-controls');
          const target = document.querySelector(`#${name}`);

          target.scrollIntoView({ behavior: 'smooth' });

          target
            .querySelectorAll(`.c-accordian__card-content`)[0]
            .classList.add('show');
        });
      });
    }
  })();

  /* SECTION 8: Share This */
  (function () {
    if ($('.p-vehicle-options, .p-van-options').length) {
      // Note: This is undocumented API for the sharethis widget. Reload the widget when we
      // visit a new page after a turbolinks load event.
      if (typeof __sharethis__ !== 'undefined' && __sharethis__.config) {
        __sharethis__.href = document.location.href;
        __sharethis__.init(__sharethis__.config);
      }
    }
  })();

  /* SECTION 9: Anchor Scroll to Section */
  (function () {
    $(document).on('click', 'a[data-section-scroll]', function (event) {
      event.preventDefault();

      const target = this.hash.split('#')[1],
        element = document.getElementsByName(target);

      if (element.length > 0) {
        element[0].scrollIntoView(true);
        window.scrollBy(0, -100);
      }
    });
  })();

  /* SECTION 10: Add tooltip custom class */
  $(function () {
    if (typeof $.fn.tooltip.Constructor === 'undefined') {
      throw new Error('Bootstrap Tooltip must be included first!');
    }
    let Tooltip = $.fn.tooltip.Constructor;
    // add customClass option to Bootstrap Tooltip
    $.extend(Tooltip.Default, {
      customClass: '',
    });
    let _show = Tooltip.prototype.show;
    Tooltip.prototype.show = function () {
      // invoke parent method
      _show.apply(this, Array.prototype.slice.apply(arguments));
      if (this.config.customClass) {
        let tip = this.getTipElement();
        $(tip).addClass(this.config.customClass);
      }
    };
  });

  /* SECTION 12: Badges auto-scroll */
  $(function () {
    const scrollContainer = $('.g-snap-carousel--autoscroll');
    let isUserScrolling = false;

    function startAutoScroll() {
      const maxScrollLeft =
        scrollContainer[0].scrollWidth - scrollContainer[0].clientWidth;

      if (!isUserScrolling && scrollContainer.scrollLeft() < maxScrollLeft) {
        scrollContainer.animate(
          {
            scrollLeft: maxScrollLeft,
          },
          30000,
          'linear'
        );
      }
    }

    function stopAutoScroll() {
      scrollContainer.stop();
    }

    let observer = new IntersectionObserver(
      function (entries) {
        entries.forEach(function (entry) {
          if (entry.isIntersecting) {
            // Start the auto-scroll after a delay when the container is in view
            setTimeout(startAutoScroll, 1500);
          } else {
            // Stop the auto-scroll when the container is not in view
            stopAutoScroll();
          }
        });
      },
      { threshold: 0.7 }
    );

    if (
      document.querySelector('.g-snap-carousel.g-snap-carousel--autoscroll')
    ) {
      observer.observe(
        document.querySelector('.g-snap-carousel.g-snap-carousel--autoscroll')
      );
    }

    // Disable auto-scroll when user starts interacting with the container
    scrollContainer.on('touchstart touchmove mouseenter wheel', function () {
      isUserScrolling = true;
      stopAutoScroll();
    });
  });

  /* SECTION 13: Truncate content */
  $(function () {
    const truncateContainers = $('.truncate-content');
    truncateContainers.each(function (_idx, truncateContainer) {
      const toggleButton = $(truncateContainer).find(
        '.truncate-content__toggle'
      );
      toggleButton.on('click', function () {
        if ($(truncateContainer).hasClass('truncate-content--hidden')) {
          $(truncateContainer).removeClass('truncate-content--hidden');
          toggleButton.text('View less');
        } else {
          $(truncateContainer).addClass('truncate-content--hidden');
          toggleButton.text('View more');
        }
      });
    });
  });
}

document.addEventListener('turbolinks:load', onSectionsLoad);
document.addEventListener('turbo:load', onSectionsLoad);
document.addEventListener('turbo:render', onSectionsLoad);
