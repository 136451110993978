const initialState = {
  // Business Details
  businessName: '',
  businessType: '',
  businessEstablished: '',
  companyNumber: '',
  vatNumber: '',
  numberOfDirectors: '',
  businessEmail: '',

  // Business Premises Details
  companyPostcode: '',
  companyAddressLine1: '',
  companyAddressLine2: '',
  companyAddressLine3: '',
  companyTown: '',
  companyCounty: '',
  companyPropertyStatus: 'Owned With Mortgage',
  yearsAtCompanyAddress: '',
  monthsAtCompanyAddress: '',
  companyTelephone: '',

  // Business Previous Premises Details
  previousCompanyPostcode: '',
  previousCompanyAddressLine1: '',
  previousCompanyAddressLine2: '',
  previousCompanyAddressLine3: '',
  previousCompanyTown: '',
  previousCompanyCounty: '',
  yearsAtPreviousCompanyAddress: '',
  monthsAtPreviousCompanyAddress: '',
};

// eslint-disable-next-line default-param-last
const OCACompanyInfo = (state = initialState, action) => {
  let newState;

  if (action.type === 'RESET_OCA_COMPANY_INFO_STATE') {
    return { ...initialState };
  }

  // Business Details
  if (action.type === 'SET_BUSINESS_NAME') {
    newState = { ...state };
    newState.businessName = action.payload;
    return newState;
  }

  if (action.type === 'SET_BUSINESS_TYPE') {
    newState = { ...state };
    newState.businessType = action.payload;
    return newState;
  }

  if (action.type === 'SET_DATE_BUSINESS_ESTABLISHED') {
    newState = { ...state };
    newState.businessEstablished = action.payload;
    return newState;
  }

  if (action.type === 'SET_COMPANY_NUMBER') {
    newState = { ...state };
    newState.companyNumber = action.payload;
    return newState;
  }

  if (action.type === 'SET_VAT_NUMBER') {
    newState = { ...state };
    newState.vatNumber = action.payload;
    return newState;
  }

  if (action.type === 'SET_NUMBER_OF_DIRECTORS') {
    newState = { ...state };
    newState.numberOfDirectors = action.payload;
    return newState;
  }

  if (action.type === 'SET_BUSINESS_EMAIL') {
    newState = { ...state };
    newState.businessEmail = action.payload;
    return newState;
  }

  // Business Premises Details
  if (action.type === 'SET_COMPANY_POSTCODE') {
    newState = { ...state };
    newState.companyPostcode = action.payload;
    return newState;
  }

  if (action.type === 'SET_COMPANY_ADDRESS_LINE_1') {
    newState = { ...state };
    newState.companyAddressLine1 = action.payload;
    return newState;
  }

  if (action.type === 'SET_COMPANY_ADDRESS_LINE_2') {
    newState = { ...state };
    newState.companyAddressLine2 = action.payload;
    return newState;
  }

  if (action.type === 'SET_COMPANY_ADDRESS_LINE_3') {
    newState = { ...state };
    newState.companyAddressLine3 = action.payload;
    return newState;
  }

  if (action.type === 'SET_COMPANY_TOWN') {
    newState = { ...state };
    newState.companyTown = action.payload;
    return newState;
  }

  if (action.type === 'SET_COMPANY_COUNTY') {
    newState = { ...state };
    newState.companyCounty = action.payload;
    return newState;
  }

  if (action.type === 'SET_COMPANY_PROPERTY_STATUS') {
    newState = { ...state };
    newState.companyPropertyStatus = action.payload;
    return newState;
  }

  if (action.type === 'SET_YEARS_AT_COMPANY_ADDRESS') {
    newState = { ...state };
    newState.yearsAtCompanyAddress = action.payload;
    return newState;
  }

  if (action.type === 'SET_MONTHS_AT_COMPANY_ADDRESS') {
    newState = { ...state };
    newState.monthsAtCompanyAddress = action.payload;
    return newState;
  }

  if (action.type === 'SET_COMPANY_TELEPHONE') {
    newState = { ...state };
    newState.companyTelephone = action.payload;
    return newState;
  }

  // Business Previous Premises Details
  if (action.type === 'SET_PREVIOUS_COMPANY_POSTCODE') {
    newState = { ...state };
    newState.previousCompanyPostcode = action.payload;
    return newState;
  }

  if (action.type === 'SET_PREVIOUS_COMPANY_ADDRESS_LINE_1') {
    newState = { ...state };
    newState.previousCompanyAddressLine1 = action.payload;
    return newState;
  }

  if (action.type === 'SET_PREVIOUS_COMPANY_ADDRESS_LINE_2') {
    newState = { ...state };
    newState.previousCompanyAddressLine2 = action.payload;
    return newState;
  }

  if (action.type === 'SET_PREVIOUS_COMPANY_ADDRESS_LINE_3') {
    newState = { ...state };
    newState.previousCompanyAddressLine3 = action.payload;
    return newState;
  }

  if (action.type === 'SET_PREVIOUS_COMPANY_TOWN') {
    newState = { ...state };
    newState.previousCompanyTown = action.payload;
    return newState;
  }

  if (action.type === 'SET_PREVIOUS_COMPANY_COUNTY') {
    newState = { ...state };
    newState.previousCompanyCounty = action.payload;
    return newState;
  }

  if (action.type === 'SET_YEARS_AT_PREVIOUS_COMPANY_ADDRESS') {
    newState = { ...state };
    newState.yearsAtPreviousCompanyAddress = action.payload;
    return newState;
  }

  if (action.type === 'SET_MONTHS_AT_PREVIOUS_COMPANY_ADDRESS') {
    newState = { ...state };
    newState.monthsAtPreviousCompanyAddress = action.payload;
    return newState;
  }

  return state;
};

export default OCACompanyInfo;
