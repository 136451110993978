import { isFilterArray } from '../../utils';

const initialState = {
  isOpen: false,
  isVisible: true,
  isFixed: false,
  filters: {},
  totalCounts: {},
  theme: null,
  selectedDropdown: null,
  isVans: null,
  controller: null,
  sort_by: null,
  filtersUpdated: false,
};

// eslint-disable-next-line default-param-last
const filterControls = (state = initialState, action) => {
  let newState;

  if (action.type === 'INITIALISE_FILTERS') {
    newState = { ...state };
    newState.filters = {
      ...action.payload,
    };
    newState.selectedDropdown = '';
    return newState;
  }

  if (action.type === 'RESET_FILTERS') {
    newState = { ...state };
    newState.filters = {};
    return newState;
  }

  if (action.type === 'SET_FILTERS') {
    newState = { ...state };
    newState.filters = {
      ...action.payload,
    };
    return newState;
  }

  if (action.type === 'SET_BODYTYPE_FILTERS') {
    newState = { ...state };
    const bodytypeCategories = (newState && newState.filters && newState.filters['bodytype_categories[]']) || [];
    if (bodytypeCategories.includes(action.payload)) {
      newState.filters = {
        ...newState.filters,
        'bodytype_categories[]': bodytypeCategories.filter((category) => category !== action.payload),
      };
    } else {
      newState.filters = {
        ...newState.filters,
        'bodytype_categories[]': [...bodytypeCategories, action.payload],
      };
    }
    return newState;
  }

  if (action.type === 'SET_FILTER') {
    newState = { ...state };

    const newSelectedFilters = { ...newState.filters };

    if (isFilterArray(action.payload.category)) {
      if (action.payload.category === 'make[]' && newState.filters['make_model_range[]']) {
        newSelectedFilters['make_model_range[]'] = newSelectedFilters['make_model_range[]'].filter((item) => {
          return item.indexOf(action.payload.value) !== 0;
        });
      }

      if (newSelectedFilters[action.payload.category].length === 1) {
        delete newSelectedFilters[action.payload.category];

        if (action.payload.category === 'make[]' && newState.filters['make_model_range[]']) {
          delete newSelectedFilters['make_model_range[]'];
        }
      } else {
        const index = newSelectedFilters[action.payload.category].indexOf(action.payload.value);
        newSelectedFilters[action.payload.category].splice(index, 1);
      }
    } else {
      delete newSelectedFilters[action.payload.category];
    }

    newState.filters = { ...newSelectedFilters };

    return newState;
  }

  if (action.type === 'SET_SORT_BY') {
    newState = { ...state };
    newState.sort_by = action.payload;
    return newState;
  }

  if (action.type === 'TOTAL_COUNTS') {
    newState = { ...state };
    if (action.payload) {
      newState.totalCounts = action.payload;
    }

    return newState;
  }

  if (action.type === 'SET_OPEN') {
    newState = { ...state };
    newState.isOpen = action.payload;
    return newState;
  }

  if (action.type === 'SET_VISIBLE') {
    newState = { ...state };
    newState.isVisible = action.payload;
    return newState;
  }

  if (action.type === 'SET_FIXED') {
    newState = { ...state };
    newState.isFixed = action.payload;
    return newState;
  }

  if (action.type === 'SET_THEME') {
    newState = { ...state };
    newState.theme = action.payload;
    return newState;
  }

  if (action.type === 'SET_IS_VANS') {
    newState = { ...state };
    newState.isVans = action.payload;
    return newState;
  }

  if (action.type === 'SET_CONTROLLER') {
    newState = { ...state };
    newState.controller = action.payload;
    return newState;
  }

  if (action.type === 'SET_FILTERS_UPDATED') {
    newState = { ...state };
    newState.filtersUpdated = action.payload;
    return newState;
  }

  if (action.type === 'SET_ELECTRIC') {
    newState = { ...state };
    if (newState.filters.hybrid_or_electric) {
      delete newState.filters.hybrid_or_electric;
    } else {
      newState.filters.hybrid_or_electric = 'true';
    }
    return newState;
  }

  if (action.type === 'SET_REMOVE_ELECTRIC') {
    newState = { ...state };
    delete newState.filters.hybrid_or_electric;
    return newState;
  }

  if (action.type === 'SET_SELECTED_DROPDOWN') {
    newState = { ...state };
    if (newState.selectedDropdown === action.payload) {
      newState.selectedDropdown = '';
    } else {
      newState.selectedDropdown = action.payload;
    }
    return newState;
  }

  return state;
};

export default filterControls;
